<template>
  <v-layout>
    <vbt-table
      :items="items"
      :headers="headers"
      :page-size="pageSize"
    >
      <template #amount="{ item }">
        {{ formatNumber(item.amount) }}
        / {{ formatNumber(item.taxAmount) }}
        / {{ formatNumber(item.amountWithTax) }}
      </template>

      <template #dateCreated="{ item: { dateCreated } }">
        {{ formatDisplayDate(dateCreated) }}
      </template>

      <template #period="{ item }">
        {{ formatDisplayDate(item.dateFrom, { dateFormat: 'yyyy-MM-dd' }) }}
        - {{ formatDisplayDate(item.dateTo, { dateFormat: 'yyyy-MM-dd' }) }}
      </template>

      <template #invoices="{ item }">
        <v-btn
          :disabled="!item.invoices && !item.invoices.length"
          color="info"
          small
          icon
          @click="showInvoices(item)"
        >
          <v-icon>
            remove_red_eye
          </v-icon>
        </v-btn>
      </template>

      <template #download="{ item }">
        <v-btn-toggle
          :value-comparator="() => false"
          mandatory
        >
          <v-btn
            v-for="(format, i) in fileFormats"
            :key="i"
            x-small
            text
            @click="downloadStatementFile(item.id, format)"
          >
            {{ format }}
          </v-btn>
        </v-btn-toggle>
      </template>
    </vbt-table>

    <ir-statements-invoices-viewer ref="statementsInvoicesViewer" />
  </v-layout>
</template>

<script>
import { formatDisplayDate, formatNumber, wrapToLoadingFn } from '@helpers';

import { StatementsApiService } from '../_services';

import IrStatementsInvoicesViewer from './IrStatementsInvoicesViewer.vue';

const headers = Object.freeze([
  { text: 'Account', value: 'accountName' },
  { text: 'Number', value: 'number' },
  { text: 'Amount/Tax/With Tax', value: 'amount' },
  { text: 'Created Date', value: 'dateCreated' },
  { text: 'Period', value: 'period' },
  { text: 'Invoices', value: 'invoices' },
  { text: 'Download', value: 'download', width: '200px' },
]);

export default {
  name: 'IrStatementsList',

  props: {
    items: {
      type: Array,
      default: () => [],
    },

    pageSize: {
      type: Number,
      default: 10,
    },
  },

  components: {
    IrStatementsInvoicesViewer,
  },

  data() {
    return {
      fileFormats: ['pdf', 'csv', 'xlsx'],

      headers,
    };
  },

  methods: {
    showInvoices({ accountName, number, invoices }) {
      const title = `${accountName} ${number}`;

      this.$refs.statementsInvoicesViewer.$_openDialog(title, invoices);
    },

    downloadStatementFile(statementId, format) {
      this.wrapToLoadingFn({
        req: StatementsApiService.getStatementFileURL.bind({}, { statementId, format }),
        loadingSpinnerType: 'fullscreen',
        onSuccess: url => window.open(url, '_blank'),
      });
    },

    formatDisplayDate,
    formatNumber,
    wrapToLoadingFn,
  },
};
</script>
