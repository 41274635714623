import HTTP from '@api';

import { generateXHRError } from '@helpers';

export const StatementsApiService = {
  async getStatements({
    accountId, orderId, from, to, pageSize = 10, pageNum = 1,
  }) {
    try {
      const { data } = await HTTP.get('statements', {
        params: {
          accountId, orderId, from, to, pageSize, pageNum,
        },
      });

      return data.items || [];
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },

  async getStatementFileURL({ statementId, format }) {
    try {
      const { data } = await HTTP.get(`statements/${statementId}/download`, {
        params: {
          format,
        },
      });

      return data.url || '';
    } catch (e) {
      return Promise.reject(generateXHRError(e));
    }
  },
};
