var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',[_c('vbt-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"page-size":_vm.pageSize},scopedSlots:_vm._u([{key:"amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatNumber(item.amount))+" / "+_vm._s(_vm.formatNumber(item.taxAmount))+" / "+_vm._s(_vm.formatNumber(item.amountWithTax))+" ")]}},{key:"dateCreated",fn:function(ref){
var dateCreated = ref.item.dateCreated;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(dateCreated))+" ")]}},{key:"period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDisplayDate(item.dateFrom, { dateFormat: 'yyyy-MM-dd' }))+" - "+_vm._s(_vm.formatDisplayDate(item.dateTo, { dateFormat: 'yyyy-MM-dd' }))+" ")]}},{key:"invoices",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"disabled":!item.invoices && !item.invoices.length,"color":"info","small":"","icon":""},on:{"click":function($event){return _vm.showInvoices(item)}}},[_c('v-icon',[_vm._v(" remove_red_eye ")])],1)]}},{key:"download",fn:function(ref){
var item = ref.item;
return [_c('v-btn-toggle',{attrs:{"value-comparator":function () { return false; },"mandatory":""}},_vm._l((_vm.fileFormats),function(format,i){return _c('v-btn',{key:i,attrs:{"x-small":"","text":""},on:{"click":function($event){return _vm.downloadStatementFile(item.id, format)}}},[_vm._v(" "+_vm._s(format)+" ")])}),1)]}}])}),_c('ir-statements-invoices-viewer',{ref:"statementsInvoicesViewer"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }